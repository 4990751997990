.button {
  /* --primary: hsl(277, 86%, 28%); */
  /* --primary: var(--purple1); */
  --primary: linear-gradient(
    90deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  /* --shadow-primary: hsl(var(--shadow-primary-hue), 90%, 50%); */
  --shadow-primary: #FFFFFF;
  --primary-hue: 0;
  --primary-lightness: 50;
  --color: hsl(0, 0%, 100%);
  --shadow-primary-hue: 180;
  --label-size: 9px;
  --shadow-secondary: hsl(var(--shadow-secondary-hue), 90%, 60%);
  --clip: polygon(0 0, 100% 0, 100% 100%, 95% 100%, 85% 100%, 8% 100%, 0 70%);
  --border: 4px;
  --shimmy-distance: 5;
  --clip-one: polygon(
    0 2%,
    100% 2%,
    100% 95%,
    95% 95%,
    95% 90%,
    85% 90%,
    85% 95%,
    8% 95%,
    0 70%
  );
  --clip-two: polygon(
    0 78%,
    100% 78%,
    100% 100%,
    95% 100%,
    95% 90%,
    85% 90%,
    85% 100%,
    8% 100%,
    0 78%
  );
  --clip-three: polygon(
    0 44%,
    100% 44%,
    100% 54%,
    95% 54%,
    95% 54%,
    85% 54%,
    85% 54%,
    8% 54%,
    0 54%
  );
  --clip-four: polygon(
    0 0,
    100% 0,
    100% 0,
    95% 0,
    95% 0,
    85% 0,
    85% 0,
    8% 0,
    0 0
  );
  --clip-five: polygon(
    0 0,
    100% 0,
    100% 0,
    95% 0,
    95% 0,
    85% 0,
    85% 0,
    8% 0,
    0 0
  );
  --clip-six: polygon(
    0 40%,
    100% 40%,
    100% 85%,
    95% 85%,
    95% 85%,
    85% 85%,
    85% 85%,
    8% 85%,
    0 70%
  );
  --clip-seven: polygon(
    0 63%,
    100% 63%,
    100% 80%,
    95% 80%,
    95% 80%,
    85% 80%,
    85% 80%,
    8% 80%,
    0 70%
  );
  font-family: "StylishFont";
  color: var(--color);
  cursor: pointer;
  background: transparent;
  text-transform: uppercase;
  font-size: 1.3rem;
  outline: transparent;
  letter-spacing: 2px;
  position: relative;
  border: 0;
  min-width: 200px;
  line-height: 60px;
  transition: background 0.2s;
  padding: 0 var(--mb-2);
}

.button:hover {
  /* --primary: hsl(277, 86%, 28%); */
  --primary: linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
}

.button:active {
  --primary: hsl(
    var(--primary-hue),
    85%,
    calc(var(--primary-lightness, 50) * 0.6%)
  );
}

.button:after,
.button:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  clip-path: var(--clip);
  z-index: -1;
}

.button:before {
  background: var(--shadow-primary);
  transform: translate(var(--border), 0);
}

.button:after {
  background: var(--primary);
}

.glitch {
  position: absolute;
  top: calc(var(--border) * -1);
  left: calc(var(--border) * -1);
  right: calc(var(--border) * -1);
  bottom: calc(var(--border) * -1);
  clip-path: var(--clip);
  display: none;
}

.button,
.glitch {
  display: block;
}

.glitch:before {
  content: "";
  position: absolute;
  top: calc(var(--border) * 1);
  left: calc(var(--border) * 1);
  right: calc(var(--border) * 1);
  bottom: calc(var(--border) * 1);
  clip-path: var(--clip);
  background: var(--primary);
  z-index: -1;
}

@keyframes glitchAnimation {
  0% {
    clip-path: var(--clip-one);
  }

  2%,
  8% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }

  6% {
    clip-path: var(--clip-two);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }

  9% {
    clip-path: var(--clip-two);
    transform: translate(0, 0);
  }

  9% {
    clip-path: var(--clip-three);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }

  10% {
    clip-path: var(--clip-three);
    transform: translate(0, 0);
  }

  14%,
  21% {
    clip-path: var(--clip-four);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }

  25% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }

  30% {
    clip-path: var(--clip-five);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }

  35%,
  45% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * -1%), 0);
  }

  40% {
    clip-path: var(--clip-six);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }

  50% {
    clip-path: var(--clip-six);
    transform: translate(0, 0);
  }

  55% {
    clip-path: var(--clip-seven);
    transform: translate(calc(var(--shimmy-distance) * 1%), 0);
  }

  60% {
    clip-path: var(--clip-seven);
    transform: translate(0, 0);
  }

  31%,
  61%,
  100% {
    clip-path: var(--clip-four);
  }
}

.button:nth-of-type(2) {
  --primary-hue: 260;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .button:hover .glitch {
    animation: glitchAnimation 2s infinite;
    background: var(--shadow-primary);
    text-shadow: 2px 2px var(--shadow-primary), -2px 2px var(--shadow-primary);
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
