.wrapper {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.contentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--bg);
  width: 90%;
  margin-bottom: var(--mb-4);
  margin-top: 75px;
  border-radius: 2rem;
}

.headingContainer {
  margin: var(--mb-4) 0;
  text-align: center;
}

.headingContainer > p {
  margin-top: var(--mb-2);
  font-size: 1.1rem;
  letter-spacing: 0.1rem;
}

.mainContainer {
  margin-top: var(--mb-2);
  margin-bottom: var(--mb-6);
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.infoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
}

.descriptionContainer,
.rulesContainer {
  border: 1px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
  padding: var(--mb-2);
  border-radius: 1rem;
  font-size: 1.1rem;
  line-height: 1.5rem;
  margin-bottom: var(--mb-2);
}

.rulesContainer > h3 {
  letter-spacing: 0.1rem;
  margin-bottom: var(--mb-1);
}

.regFormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formTextContainer {
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
}

.teamDetailsContainer {
}

.teamMemberDetailsContainer {
  display: flex;
  flex-direction: column;
  flex-basis: 40%;
  align-items: center;
}

.detailsContainer {
  /* background-color: var(--secondary); */
  background: linear-gradient(-45deg, var(--primary), var(--tertiary));
  padding: var(--mb-2);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: var(--mb-2) 0;
  max-height: fit-content !important;
  width: 100%;
}

/* Team Member Details */
.formHeader {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: var(--mb-2) 0;
}
.formHeader > p {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
  font-family: var(--primaryFontBold);
}

.formIcon {
  font-size: 2rem !important;
  cursor: pointer;
  transition: 0.2s all !important;
}

.formIconInverse {
  transform: rotateX(180deg);
}

.formWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  transition: 0.5s display;
}

/* File Handling */
.projectDetailsContainer {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: var(--bgBlue);
  padding: var(--mb-2);
  border-radius: 2rem;
  margin: var(--mb-4) 0;
}

.projectFileHeading {
  font-size: 1.1rem;
  margin: var(--mb-1) 0;
  letter-spacing: 0.1rem;
  font-weight: 600;
}
.instructions {
  margin-bottom: var(--mb-2);
  font-style: italic;
}

/* Project Images */

.projectImagesContainer {
  flex-basis: 40%;
  margin-bottom: var(--mb-3);
}

/* Abstract PDF */

.abstractPDFContainer {
  flex-basis: 40%;
}

.fileInput {
  color: transparent;
}
.fileInput::-webkit-file-upload-button {
  visibility: hidden;
}
.fileInput::before {
  content: "Upload";
  color: black;
  display: inline-block;
  background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3);
  border: 1px solid #999;
  border-radius: 3px;
  padding: 5px 8px;
  outline: none;
  white-space: nowrap;
  -webkit-user-select: none;
  cursor: pointer;
  text-shadow: 1px 1px #fff;
  font-weight: 700;
  font-size: 10pt;
}
.fileInput:hover::before {
  border-color: black;
}
.fileInput:active {
  outline: 0;
}
.fileInput:active::before {
  background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9);
}

.projectFileName {
  display: flex;
  align-items: center;
  margin: var(--mb-1) 0;
}

.projectFileName:first-child {
  /* margin-top: var(--mb-1); */
}

.projectFileName > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin-right: var(--mb-1);
}

.deleteIcon {
  transition: 0.2s all;
  cursor: pointer;
  color: rgba(214, 31, 31, 0.74);
}

.deleteIcon:hover {
  transform: scale(1.2);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .formIcon:hover {
    transform: scale(1.3);
  }
  .formIconInverse:hover {
    transform: scale(1.3) rotateX(180deg);
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  /* .formContainer {
    flex-direction: column;
  }

  .leaderDetailsContainer {
    display: flex;
    justify-content: center;
  } */
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  /* .detailsContainer {
    width: 70%;
  } */
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  /* .detailsContainer {
    width: 90%;
  } */

  .projectDetailsContainer {
    width: 95%;
  }
  .projectImagesContainer,
  .abstractPDFContainer {
    flex-basis: 70%;
  }
}
