.heading {
  font-size: 2.5rem;
  text-transform: uppercase;
  font-weight: bolder;
  animation: glitch 3s infinite;
  font-family: "StylishFont";
  letter-spacing: 0.1rem;
  text-align: center;
}

.heading::after {
  position: absolute;
  content: attr(data-text);
  bottom: 0;
  left: 0;
  color: #fff;
  background-color: #242423;
  width: 100%;
  visibility: hidden;
  animation: clip 4s infinite;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .heading {
    font-size: 2rem;
  }
}

@keyframes glitch {
  30% {
    text-shadow: 4px -4px 0px rgba(60, 85, 255, 1);
  }
  32% {
    text-shadow: -4px 4px 0px rgba(255, 85, 0, 1);
  }
  0%,
  28%,
  34% {
    text-shadow: none;
  }
  31%,
  32% {
    transform: translate(-7px, 7px);
  }
  32%,
  33% {
    transform: translate(7px, -7px);
  }
  31%,
  33% {
    transform: translatex(0);
  }

  50% {
    text-shadow: 4px 4px 0px rgba(60, 85, 255, 1);
  }
  52% {
    text-shadow: -4px -4px 0px rgba(255, 85, 0, 1);
  }
  48%,
  54% {
    text-shadow: none;
  }
  49%,
  52% {
    transform: translatex(0);
  }
  50% {
    transform: translate(7px, 7px);
  }
  51% {
    transform: translate(-7px, -7px);
  }
}

@keyframes clip {
  28%,
  31% {
    clip: none;
    left: 0;
    visibility: visible;
    color: #fff;
  }
  30% {
    clip: rect(60px, 999px, 80px, 0px);
    left: -5px;
    color: #d1c9b8;
  }
  48%,
  52% {
    clip: none;
    left: 0;
    visibility: visible;
    color: #fff;
  }
  50% {
    clip: rect(30px, 999px, 55px, 0px);
    left: 5px;
    color: #d1c9b8;
  }
}
