.accordianHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 95%;
}

.statusContainer {
  flex-basis: 20%;
  display: flex;
  justify-content: center;
}

.icon {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tableLink:hover {
  text-decoration: underline;
  cursor: pointer;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {

}
