.sponsorWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.headingContainer {
  margin-top: var(--mb-6);
  padding-top: var(--mb-3);
}

.mainContainer {
  width: 80%;
  background-color: var(--bg);
  border-radius: 1rem;
  margin: var(--mb-6) 0;
  padding: var(--mb-3) 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
}

.extendWidthContainer {
  width: 100%;
}

.sponsorSubheading {
  justify-content: center;
  font-weight: 700;
  color: aqua;
  font-size: 1.75rem;
}

.sponsorContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: var(--mb-3) var(--mb-4);
}

.sponsorImages {
  flex-wrap: wrap;
  justify-content: center;
}

.sponsorImages,
.sponsorSubheading {
  width: 100%;
  display: flex;
  align-items: center;
}

.sponsorImage {
  margin: 20px;
  transition: 0.2s all;
  cursor: pointer;
}

.titleSponsorImage {
  width: 450px;
}

.normalSponsorImage {
  width: 300px;
}

.sponsorImage:hover {
  transform: scale(1.1);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .sponsorContainer {
    width: 95%;
  }

  .titleSponsorImage {
    width: 300px;
  }
}
