.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.headerContainer {
  margin: var(--mb-6) var(--mb-5);
  padding-top: var(--mb-3);
}
.contentContainer {
  width: max-content;
  max-width: 90%;
  display: flex;
  justify-content: center;
  border-radius: 1rem;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  margin-bottom: var(--mb-4);
}
.cards {
  padding: var(--mb-6);
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.cards > li {
  align-items: center;
  display: flex;
  justify-content: center;
  padding: var(--mb-3);
  position: relative;
  transition: 0.5s all;
}

.extraCard {
  padding: var(--mb-6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.extraCard > img {
  margin-bottom: var(--mb-6);
  width: 64px;
}

@media only screen and (max-width: 1023px) {
  .cards {
    padding: var(--mb-4);
    flex-direction: column;
  }
  .cards > li {
    padding: var(--mb-1);
    margin-bottom: var(--mb-3);
  }
  .cards > li :last-child {
    margin-bottom: 0;
  }
}
