.button {
  padding: var(--mb-1) var(--mb-2);
  border-radius: 1rem;
  font-size: 1rem;
  text-transform: uppercase;
  background-image: linear-gradient(
    45deg,
    var(--secondary) 50%,
    transparent 50%
  );
  transition: 0.2s all;
  cursor: pointer;
  appearance: none;
  border: 2px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
  text-decoration: none;
  font-weight: 700;
  color: var(--text);
  margin: 0 var(--mb-4);
}

.button:hover {
  transform: scale(1.1);
}
