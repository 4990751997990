.itemRow {
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.itemHeader {
  color: #aaa7a7;
  flex-basis: 30%;
}

.itemContent {
  flex-basis: 60%;
}

hr {
  border: 0.05rem solid #302d2d;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .itemRow {
    flex-direction: column;
    align-items: center;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .itemHeader {
    font-size: 1rem;
  }

  .itemContent {
    font-size: 1rem;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .itemHeader {
    font-size: 0.8rem;
  }

  .itemContent {
    font-size: 0.8rem;
  }
}
