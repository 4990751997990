.wrapper {
  display: flex;
  min-height: 100vh;
  width: 100%;
  justify-content: center;
  padding-top: var(--mb-6);
}

.mainWrapper {
  display: flex;
  flex-direction: column;
  width: 90%;
  background-color: var(--bg);
  height: max-content;
  padding: var(--mb-6) 0;
  margin-top: var(--mb-6);
  border-radius: 1rem;
  border: 3px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}

.contentContainer {
  display: flex;
  justify-content: space-evenly;
}

.contentContainer > * {
  /* margin: 0 var(--mb-3); */
}

.coverBox {
  flex-basis: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.coverBox > img {
  width: 250px;
  border-radius: 4px;
}

.textBox {
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 90%;
}

.headingContainer {
  text-align: center;
  margin-bottom: var(--mb-3);
}

.textBox > p {
  letter-spacing: 0.1rem;
  margin-bottom: var(--mb-4);
  line-height: 1.5rem;
  text-align: justify;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .contentContainer {
    flex-direction: column;
    align-items: center;
  }

  .headingContainer {
    margin-top: var(--mb-4);
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
