.wrapper * {
  margin-left: auto;
  margin-right: auto;
}
.wrapper {
  width: 100%;
  height: 100vh;
  align-items: center;
  overflow: auto;
  /* background-image: url(../../assets/images/projects_bg.png); */
}
.headingContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* margin-top: 75px; */
  padding: var(--mb-4) var(--mb-1);
  width: 100%;
}

.headingContainer > p {
  margin-top: var(--mb-1);
  display: flex;
  align-items: center;
}

.headingContainer > p .CLICK_HERE {
  cursor: pointer;
  color: #000000;
  font-weight: 600;
  transition: 0.2s all;
}

.headingContainer > p .CLICK_HERE:hover {
  transform: scale(1.1);
}

.TECHNOVATION {
  letter-spacing: 0.1rem;
  font-weight: 600;
}

.brandLogo {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 200px;
}
.cards_wrapper {
  width: 90vw;
  height: 75vh;
  display: flex;
  overflow: hidden;
  z-index: var(--z-normal);
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  border-radius: 2rem;
}
.cards_wrapper::after {
  position: absolute;
  content: "";
  display: block;
  width: 90vw;
  height: 75vh;
  box-shadow: inset 0 -120px 60px -60px black;
  border-radius: 0 0 2rem 2rem;
  pointer-events: none;
  z-index: var(--z-tooltip);
}
.cards_wrapper_inside {
  margin: 2rem 1rem 1rem 0;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}
.cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(275px, 1fr));
  gap: 3rem 2rem;
  margin: 2rem 0;
  padding: 0 1rem 4rem 1rem;
  list-style-type: none;
}

.cards li {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1023px) {
  .headingContainer {
    padding-top: 5rem;
  }
  .cards_wrapper {
    height: 72vh;
  }
  .cards_wrapper::after {
    height: 72vh;
    box-shadow: inset 0 -80px 40px -40px black;
  }
}
