.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-bottom: var(--mb-6);
}

.headingContainer {
  margin: var(--mb-2) 0;
  text-align: center;
}

.headingContainer {
  font-family: "StylishFont";
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  width: 90%;
  font-size: 1.5rem;
}

.cardsContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.regButtonContainer {
  margin: var(--mb-2) 0;
}

.cardBox {
  margin: var(--mb-2) var(--mb-4);
}
