.quote_wrapper {
  width: 100%;
  /* height: 100vh; */
  padding: var(--mb-6) 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bgDiv {
  position: absolute;
  height: 100%;
  width: 100%;
  filter: blur(3px);
  /* opacity: 0.5; */
  background-image: url(../../../../assets/bg/quote_bg_1.jpg);
  background-attachment: fixed;
  background-size: cover;
}

.quote_card {
  /* box-sizing: border-box; */
  width: 70%;
  background-color: var(--bg);
  color: var(--text);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--mb-4);
  margin: var(--mb-6) 0;
  border-radius: 40px;
  border: 3px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}
.quote_card .quote_icon {
  width: 100px;
  height: 60px;
  margin: auto;
}
.quote {
  font-size: 1.5rem;
  font-weight: bold;
  text-align: center;
  margin-top: var(--mb-1);
  margin-bottom: var(--mb-1);
  line-height: 2rem;
}
.author {
  margin-top: var(--mb-2);
  font-size: var(--mb-3);
  text-align: center;
}
@media only screen and (max-width: 1023px) {
  .quote_card {
    padding: var(--mb-2);
    width: 70%;
  }
}
@media only screen and (max-width: 767px) {
  .quote {
    font-size: var(--mb-3);
  }
  .quote_card {
    width: 90%;
  }
}
