.wrapper {
  background-color: #211f1f;
  width: 100%;
  border-radius: 1rem;
  padding: var(--mb-2);
}

.eventsContainer {
  display: flex;
  flex-wrap: wrap;
}

.eventLink {
  margin: var(--mb-2);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;
}

.eventLink:hover {
  transform: scale(1.05);
  color: var(--orange1);
}

.arrowIcon {
}

.regInfoContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.regInfoContainer > * {
  margin: var(--mb-1) 0;
}
