.wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--mb-4) 0;
}

.headerContainer {
  padding-top: var(--mb-2);
  margin: var(--mb-4) 0;
  text-align: center;
}

.headerContainer > p {
  margin: var(--mb-2) 0;
}

.contentContainer {
  width: 80%;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  border-radius: 1rem;
  padding: var(--mb-3);
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
}
/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .contentContainer {
    width: 90%;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
