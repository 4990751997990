.wrapper {
  position: fixed;
  top: 90vh;
  left: 15px;
  background-color: var(--bg);
  border-radius: 50%;
  padding: var(--mb-1);
  cursor: pointer;
  transition: 0.2s all;
  z-index: 100;
}

.wrapper:hover {
  transform: scale(1.1);
}

.icon {
  font-size: 2rem !important;
}
