.modalOverlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  backdrop-filter: blur(8px);
}

.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 75vw;
  max-height: 80vh;
  background-color: var(--bg);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 1rem;
  outline: none;
  padding: 20px 10px;
}

.modalContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: var(--mb-3);
}

.contentWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.headingContainer {
  width: 80%;
}

.bgDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  filter: blur(5px);
}

.mainWrapper {
  width: 80vw;
  height: 80vh;
  background-color: var(--bg);

  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-wrap: wrap;
  z-index: 10;
}

.contentContainer {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: var(--mb-2) 0;
}

.contentBox {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--mb-2);
  margin: var(--mb-2) var(--mb-4);
  border: 2px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
  padding: var(--mb-2);
  border-radius: 5px;
}

.contentBox > h2 {
  margin-bottom: var(--mb-1);
  text-transform: uppercase;
  text-align: center;
}

.contentBox > div {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.eventLink {
  text-decoration: none;
  color: var(--text);
  margin-bottom: var(--mb-1);
  transition: 0.2s all;
  display: flex;
  align-items: center;
}

.eventLink:hover {
  color: var(--orange1);
  transform: scale(1.05);
}

.arrowIcon {
  font-size: 1rem;
}

.close_icon {
  font-size: 2.5rem !important;
  transition: 0.2s all !important;
  cursor: pointer;
  /* position: fixed; */
  position: absolute;
  top: 1rem;
  right: 1rem;
  /* border: 1px solid white; */
  border-radius: 50%;
  padding: var(--mb-1);
}

.close_icon:hover {
  transform: scale(1.2);
  background-color: var(--bgBlue);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .modalContainer {
    flex-direction: column;
    padding: var(--mb-1);
  }
  .contentContainer {
    padding: 5px 0px 10px 0px;
    width: unset;
  }
  .contentContainer div {
    height: 100%;
    overflow: unset;
  }
  .imageContainer {
    width: fit-content;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modalWrapper {
    max-width: 80vw;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .modalWrapper {
    max-width: 90vw;
  }
}
