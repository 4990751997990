.ham_svg {
  fill-rule: evenodd;
  clip-rule: evenodd;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-miterlimit: 1.5;
  cursor: pointer;
  transform: rotate(0deg) !important;
}

.ham_svg path {
  fill: none;
  stroke: white;
  stroke-width: 2.5px;
}

.ham_svg + .ham_svg {
  margin-left: 1.5rem;
}

#top,
#bottom {
  stroke-dasharray: 30, 75.39;
  transition: all 0.4s cubic-bezier(0.6, 0.33, 0.67, 1.29);
}

.ham_svg.active #top,
.ham_svg.active #bottom {
  stroke-dasharray: 75.39;
  stroke-dashoffset: -60;
}

.ham_svg:nth-child(2) {
  transform: rotate(0deg);
  transition: transform 0.2s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.ham_svg:nth-child(2) path {
  transition: all 0.2s ease-in-out 0.4s;
}

.ham_svg:nth-child(2).active {
  transform: rotate(180deg);
}

.rocket #top {
  stroke-dasharray: 30, 88;
}
