.menuPane {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  padding: var(--mb-1) 0;
}

.itemContainer {
  background-color: var(--bg);
  margin: var(--mb-1);
  border-radius: 2rem;
  padding: var(--mb-1);
  display: flex;
  align-items: center;
  cursor: pointer;
  border: 1px solid transparent;
}

.itemContainer > span {
  display: inline-block;
}

.itemContainer > img {
  display: inline-block;
  width: 35px !important;
  height: 35px !important;
  margin-right: var(--mb-1);
  padding: var(--mb-1);
  border-radius: 50%;
  background-color: var(--bg);
}

.itemContainerOff {
  background-color: var(--bg);
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
  transition: 0.2s all;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .itemContainer:hover {
    transform: scale(1.05);
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .menuPane {
    width: 200vw !important;
  }

  .itemContainer > img {
    width: 25px !important;
    height: 25px !important;
    margin-right: var(--mb-1);
    padding: 4px;
    border-radius: 50%;
    background-color: var(--bg);
  }

  .itemContainer {
    background-color: var(--bg);
    margin: var(--mb-1);
    border-radius: 2rem;
    padding: 4px;
    display: flex;
    align-items: center;
    cursor: pointer;
    border: 1px solid transparent;
  }
}
