.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  width: 100%;
  background-color: var(--bg);
}

.headingContainer {
  margin-top: var(--mb-6);
  padding-top: var(--mb-3);
}

.content_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%;
  z-index: 10;
}

.bgDiv {
  width: 100%;
  height: 100%;
  /* padding: var(--mb-6) 0; */
}

.bgDiv > img {
  position: absolute;
  height: 100%;
}

.content_container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  margin: var(--mb-3);
}

.image_box {
  flex-basis: 25%;
  text-align: center !important;
  align-self: center;
  margin-right: 2%;
}

.image_box > img {
  margin: auto !important;
}

.text_box {
  align-self: center;
  flex-basis: 65%;
  padding: var(--mb-3);
  border-radius: 3em;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  justify-content: center;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  border: 3px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}

.text_box > p {
  line-height: 1.75rem;
  letter-spacing: 1.5px;
  text-align: justify;
}

.content_container:nth-child(even) > .text_box {
  order: 1;
}

.content_container:nth-child(even) > .image_box {
  order: 2;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .content_container {
    padding: var(--mb-6) 0;
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .content_container {
    flex-direction: column;
  }
  .content_container:nth-child(even) > .text_box {
    order: 2;
  }

  .content_container:nth-child(even) > .image_box {
    order: 1;
  }

  .image_box {
    margin-bottom: var(--mb-6);
  }
  .text_box {
    margin-bottom: var(--mb-6);
  }
  .text_box > h1 {
    margin-bottom: var(--mb-3);
  }
}

.subheading {
  letter-spacing: 0.1rem;
  font-size: 2rem;
  line-height: 1;
  font-weight: bolder;
  margin-bottom: var(--mb-2);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .content_container {
    flex-direction: column;
  }

  .image_box {
    order: 1;
  }

  .text_box {
    order: 2;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .content_container {
    width: 80%;
  }
  .txt {
    margin-top: 20%;
  }
  .text_box > h1 {
    margin-bottom: var(--mb-3);
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .content_container {
    width: 90%;
  }

  .text_box > p {
    text-align: left;
  }

  .text_box {
    margin-bottom: var(--mb-6);
    width: 100%;
  }
}
