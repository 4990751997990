@import url("https://fonts.googleapis.com/css2?family=Special+Elite&display=swap");

.modalOverlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  backdrop-filter: blur(8px);
}

.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 75vw;
  max-height: 90vh;
  background-color: var(--bg);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 1rem;
  outline: none;
  padding: 20px;
}

.modalContainer {
  display: flex;
  flex-direction: row;
  padding: var(--mb-3);
}

.imageNameContainer {
  /* margin: var(--mb-2); */
  text-align: center;
  flex-basis: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.imageNameContainer > img {
  width: 200px;
  border-radius: 50%;
  margin: var(--mb-2) 0;
  border: 3px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}

.name {
  font-size: 1.5rem;
  margin: var(--mb-2) 0;
}

.iconPlusText {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: var(--mb-2);
}

.quoteLinkContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* margin: var(--mb-2); */
  text-align: center;
  flex-basis: 60%;
}

.quote {
  position: relative;
  font-family: "Special Elite";
  width: 80%;
  padding: var(--mb-3);
  font-size: 1.2rem;
  margin-bottom: var(--mb-4);
}

.quote:before,
.quote:after {
  position: absolute;
  color: #f1efe6;
  font-size: 4rem;
  width: 2rem;
  height: 2rem;
}

.quote:before {
  content: "“";
  top: 0;
  left: 0;
}

.quote:after {
  content: "”";
  right: 0;
  bottom: 0;
}

.iconsBox {
  display: flex;
  justify-content: center;
}

.icon {
  width: 35px;
  margin: 0 var(--mb-2);
  cursor: pointer;
  transition: 0.2s all;
}

.close_icon {
  font-size: 2.5rem !important;
  transition: 0.2s all !important;
  cursor: pointer;
  /* position: fixed; */
  position: absolute;
  top: 1rem;
  right: 1rem;
  /* border: 1px solid white; */
  border-radius: 50%;
  padding: var(--mb-1);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .icon:hover {
    transform: scale(1.2);
  }

  .close_icon:hover {
    transform: scale(1.2);
    background-color: var(--bgBlue);
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .modalContainer {
    flex-direction: column;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modalWrapper {
    max-width: 80vw;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .modalWrapper {
    max-width: 90vw;
  }

  .quote {
    width: 90%;
  }
}
