.domains {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* padding: 20px;
  margin-bottom: var(--mb-4); */
  padding: var(--mb-4) 0;
  text-align: center;
  position: relative;
}

.bgDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  background-image: url(../../../../assets/bg/quote_bg_1.jpg);
  background-attachment: fixed;
  background-size: cover;
  filter: blur(2px);
}

.headerContainer {
  margin: var(--mb-3) 0;
  width: 90%;
}

.domain_cards {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
}
.domain_card {
  align-items: center;
  display: flex;
  position: relative;
  border-radius: var(--mb-6);
  background-color: var(--bg);
  margin: 1%;
  width: 25%;
  height: 25vh;
  transition: 0.5s all;
}
.domain_card:hover {
  transform: scale(1.05);
}
.domain_image {
  border-radius: var(--mb-6);
  box-shadow: 0 0 20px black;
  position: absolute;
  top: 0;
  left: 0;
  height: 25vh;
  width: 100%;
  transition: 0.5s;
  -webkit-filter: grayscale(0) blur(0);
  filter: grayscale(0) blur(0);
}
.domain_image:hover {
  opacity: 0.2;
  -webkit-filter: grayscale(80%) blur(2px);
  filter: grayscale(80%) blur(2px);
}
.domain_content {
  padding: 10px;
  opacity: 0;
  font-size: var(--mb-2);
}
.domain_name {
  font-size: var(--mb-3);
  font-weight: bold;
  word-wrap: break-word;
  margin-bottom: var(--mb-1);
}
.domain_card:hover .domain_content {
  opacity: 1;
}

@media only screen and (max-width: 1023px) {
  .domains {
    margin-bottom: var(--mb-6);
  }
  .domain_cards {
    width: 100%;
  }
  .domain_card {
    box-shadow: 0 0 20px black;
    display: flex;
    flex-direction: column;
    align-items: baseline;
    width: 48%;
    box-sizing: border-box;
    height: 35vh;
    margin-top: var(--mb-3);
  }
  .domain_card:hover {
    transform: none;
  }
  .domain_image {
    box-shadow: none;
    position: relative;
    border-radius: var(--mb-6) var(--mb-6) 0 0;
    width: 100%;
    height: 25vh;
  }
  .domain_image:hover {
    opacity: 1;
    -webkit-filter: grayscale(0) blur(0);
    filter: grayscale(0) blur(0);
  }
  .domain_content {
    width: 100%;
    opacity: 1;
    font-size: var(--mb-3);
  }
  .domain_name {
    font-size: var(--mb-4);
  }
}
@media only screen and (max-width: 768px) {
  .domain_card {
    width: 70%;
    height: 42.5vh;
  }
  .domain_name {
    font-size: var(--mb-3);
  }
  .domain_content {
    /* margin-top: var(--mb-1); */
    padding: var(--mb-1);
    font-size: var(--mb-2);
  }
}
