.extraCard {
  padding: var(--mb-6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.extraCard > img {
  margin-bottom: var(--mb-6);
  width: 64px;
}
