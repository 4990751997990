.info_wrapper {
  background-image: url(../../../../assets/bg/quote_bg.jpg);
  background-attachment: fixed;
  background-size: cover;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.information {
  background-color: var(--bg);
  align-items: center;
  text-align: center;
  border-radius: var(--mb-4);
  width: 70%;
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: space-evenly;
  flex-direction: column;
  padding: var(--mb-3);
  margin: var(--mb-4) var(--mb-2) var(--mb-6) var(--mb-2);
  font-size: var(--mb-4);
  font-weight: bolder;
  border: 3px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}

.datetime {
  display: flex;
  justify-content: center;
}

.kTalkLogo {
  width: 250px;
}

.information h2 {
  padding: var(--mb-2);
}
.information p {
  display: flex;
  padding: var(--mb-1) 0;
  justify-content: center;
  align-items: center;
}
.contact {
  display: flex;
  flex-direction: column;
  height: auto;
  font-size: large;
  word-wrap: break-word;
}
@media only screen and (max-width: 1023px) {
  .information {
    display: flex;
    flex-direction: column;
    font-size: var(--mb-2);
    width: 90%;
  }
}
