.modalOverlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  backdrop-filter: blur(8px);
}

.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  min-width: 40vw;
  max-width: 75vw;
  max-height: 80vh;
  background-color: var(--bg);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 1rem;
  outline: none;
  padding: 20px 10px;
  box-shadow: 5px 5px 15px black;
}

.modalContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  padding: var(--mb-1);
  align-items: center;
}

.closeIcon {
  font-size: 2.5rem !important;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
  border-radius: 50%;
  padding: var(--mb-1);
  transition: 0.2s all !important;
}

.closeIcon:hover {
  transform: scale(1.2);
  background-color: var(--bgBlue);
}

.contentContainer {
  padding: 1rem 0 0 0;
  width: 100%;
  text-align: center;
  /* font-size: 1.5rem; */
}

.infoContainer {
  list-style-type: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: bold;
}
.infoContainer > li {
  display: flex;
  align-items: center;
  text-align: center;
  padding: 0.5rem 0;
}

.teamMemberDetailsContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.detailsContainer {
  background: linear-gradient(-45deg, var(--primary), var(--tertiary));
  padding: var(--mb-2);
  border-radius: 1rem;
  margin-bottom: var(--mb-3);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-height: fit-content !important;
}

.formHeader {
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: var(--mb-2) 0;
}
.formHeader > p {
  font-size: 1.2rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  font-weight: 700;
  font-family: var(--primaryFontBold);
}

.formWrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: hidden;
  transition: 0.5s display;
}

.formIcon {
  font-size: 2rem !important;
  cursor: pointer;
  transition: 0.2s all !important;
}

.formIconInverse {
  transform: rotateX(180deg);
}

/* Media Queries */

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .contentContainer {
    width: 100%;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modalWrapper {
    max-width: 80vw;
  }
  .detailsContainer {
    width: 70%;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .modalWrapper {
    max-width: 90vw;
  }
  .detailsContainer {
    width: 90%;
  }
}
