@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap");

@import url("https://fonts.googleapis.com/css?family=Montserrat:400,500,600,700");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* html,body{
  overflow:hidden;
  background-color: #f4f6ff; 
} */

.outer {
  display: flex;
  height: 100vh !important;
  justify-content: center;
  align-items: center;
  background: var(--orange2);
  background: -moz-linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  background: linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
}

.container {
  width: 100vw;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: "Poppins", sans-serif;
  position: relative;
  left: 6vmin;
  text-align: center;
}

.cogwheel1,
.cogwheel2 {
  transform: scale(0.7);
}

.cog1,
.cog2 {
  width: 40vmin;
  height: 40vmin;
  border-radius: 50%;
  border: 6vmin solid var(--purple1);

  position: relative;
  z-index: 5;
}

.cog2 {
  border: 6vmin solid #4f8a8b;
}

.top,
.down,
.left,
.right,
.lefttop,
.leftdown,
.righttop,
.rightdown {
  width: 10vmin;
  height: 10vmin;
  background-color: var(--purple1);
  position: absolute;
}

.cog2 .top,
.cog2 .down,
.cog2 .left,
.cog2 .right,
.cog2 .lefttop,
.cog2 .leftdown,
.cog2 .righttop,
.cog2 .rightdown {
  background-color: #4f8a8b;
}

.top {
  top: -14vmin;
  left: 9vmin;
}

.down {
  bottom: -14vmin;
  left: 9vmin;
}

.left {
  left: -14vmin;
  top: 9vmin;
}

.right {
  right: -14vmin;
  top: 9vmin;
}

.lefttop {
  transform: rotateZ(-45deg);
  left: -8vmin;
  top: -8vmin;
}

.leftdown {
  transform: rotateZ(45deg);
  left: -8vmin;
  top: 25vmin;
}

.righttop {
  transform: rotateZ(45deg);
  right: -8vmin;
  top: -8vmin;
}

.rightdown {
  transform: rotateZ(-45deg);
  right: -8vmin;
  top: 25vmin;
}

.cog2 {
  position: relative;
  left: -10.2vmin;
  bottom: 10vmin;
}

.firstfour,
.secondfour {
  color: var(--blue1);
}

.firstfour {
  position: relative;
  left: 6vmin;
  font-size: 40vmin;
  margin-right: 2%;
  font-family: "StylishFont";
}

.secondfour {
  position: relative;
  right: 18vmin;
  font-family: "StylishFont";
  font-size: 40vmin;
  margin-left: 7%;
}

.wrongpara {
  font-family: "Copperplate", Papyrus, fantasy;
  font-weight: 600;
  font-size: x-large;
  color: white;
  justify-content: center;
  align-items: center;
  margin-bottom: 1%;
}

@keyframes float2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(28px);
  }
}

@keyframes floatReverse2 {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-28px);
  }
}
/* 
@mixin animation($delay, $duration, $animation) {
  animation-delay: $delay;
  animation-duration: $duration;
  animation-name: $animation;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
} */

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 4%;
}

.wrapper {
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  border-radius: 2rem;
  width: 95%;
  height: 90%;
  display: grid;
  justify-content: center;
  align-items: center;
}
