.nav_wrapper {
  position: fixed;
  top: 0;
  right: 0;
  max-height: 100vh;
  background-color: var(--bg);
  overflow-x: hidden;
  transition: 0.5s all !important;
  z-index: var(--z-fixed) !important;
}

.nav_wrapper > * {
  /* opacity: 0.5 !important; */
}

.nav_open_container {
  position: fixed;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
  z-index: var(--z-fixed) !important;
}

.close_icon {
  font-size: 2.5rem !important;
  transition: 0.2s all !important;
  cursor: pointer;
  /* position: fixed; */
  position: absolute;
  top: 1rem;
  right: 1rem;
  /* border: 1px solid white; */
  border-radius: 50%;
  padding: var(--mb-1);
}

.close_icon:hover {
  transform: scale(1.2);
  background-color: var(--bgBlue);
}

.nav_container {
  display: grid;
  grid-template-columns: 33.3% 33.3% 33.3%;
}

.nav_item {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: var(--mb-6);
  text-align: center;
  cursor: pointer;
  transition: 0.2s all;
  /* border: 1px solid #ffffff; */
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, var(--purple1), var(--pink2)) 1 !important;
  border-top: none;
  border-left: none;
}

.nav_item:nth-child(2n) {
  border-image: linear-gradient(to left, var(--purple1), var(--pink2)) 1 !important;
}

.nav_item:nth-child(13) {
  border-bottom-width: 0px;
}

.nav_item:nth-child(14) {
  border-bottom-width: 0px;
}

.nav_item:hover {
}

.nav_item:hover .nav_icon,
.nav_item:hover .nav_icon_image {
  animation: animateNavItem 0.3s;
}

.nav_item p {
  margin-top: var(--mb-1);
  text-align: center;
  color: white;
}

.userItem > p {
  font-size: 1.5rem;
}

.nav_icon {
  font-size: 5rem !important;
  color: white;
}

.nav_icon_image {
  width: 125px;
}

@keyframes animateNavItem {
  0% {
    transform: rotate(0);
  }
  30% {
    transform: rotate(10deg);
  }
  60% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0);
  }
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .nav_open {
    width: 50vw;
    right: 0vw;
  }
  .nav_item:nth-of-type(3n) {
    border-right-width: 0;
  }

  .nav_close {
    width: 0vw;
    right: -50vw;
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .nav_open {
    width: 100vw;
    right: 0vw;
  }

  .nav_close {
    width: 0vw;
    right: -50vw;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .nav_item:nth-of-type(3n) {
    border-right-width: 0;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .nav_container {
    grid-template-columns: 50% 50%;
  }

  .nav_item:nth-of-type(2n) {
    border-right-width: 0 !important;
  }
}
