.cardContainer {
  height: 190px;
  box-shadow: 0 0 5px #000000;
  width: 195px;
  margin: var(--mb-4);
  border-radius: 9px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition: 0.2s all;
  background-color: var(--bg);
  border: 1px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}

.cardContainer:hover {
  transform: scale(1.1);
}

.cardContainer:hover .title {
  transform: scale(1.1);
  font-weight: 900;
}

.title {
  /* margin-top: 16px; */
  margin-bottom: var(--mb-2);
  font-size: 1.1em;
  color: rgb(255, 255, 255);
  /* color: #000000; */
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  transition: 0.2s all;
}

.imageContainer {
  text-align: center;
  margin-top: var(--mb-1);
  /* margin-top: 15px;
  margin: 19px 55px !important; */
}
.image {
  width: 80px;
  height: 80px;
}

@media (prefers-reduced-motion: no-preference) {
  .event_logo {
    /* animation: event-logo-spin infinite 20s linear; */
  }
}
@keyframes event-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
