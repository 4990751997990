.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
}

.profileCard {
  background-color: #211f1f;
  width: 40%;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 1rem;
  padding: 1rem 1.5rem;
  margin: 2rem 0;
  box-shadow: 0.1rem 0 0.1rem #02e0e4, 0 0.1rem 0.1rem #d500f8;
}

.cardHeading {
  color: #02e0e4;
  display: flex;
  align-items: center;
}

.verifiedCEGianImg {
  width: 30px;
}

.verificationText {
  margin-top: var(--mb-2);
  color: var(--orange2);
  font-weight: 600;
  letter-spacing: 0.1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  transition: 0.2s all;
}

.verificationText:hover {
  transform: scale(1.1);
}

.dataContainer {
  width: 100%;
  padding: 1rem 0;
}

.smallCardsContainer {
  width: 40%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .profileCard {
    width: 50%;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .profileCard {
    width: 60%;
  }

  .smallCardsContainer {
    flex-direction: column;
    align-items: center;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .profileCard {
    width: 80%;
  }

  .cardHeading {
    font-size: 1.5rem;
  }

  .smallCardsContainer {
    flex-direction: column;
    align-items: center;
  }
}
