.heading {
  background: linear-gradient(45deg, var(--purple1), var(--bg));
  border-radius: 4rem;
  color: var(--text);
  font-weight: bolder;
  text-shadow: 0 0 6px var(--bg);
  margin-bottom: var(--mb-1);
  text-align: center;
  padding: var(--mb-1);
  font-family: "StylishFont";
  font-size: 1.8rem;
}
