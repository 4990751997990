.wrapper {
  border: 1px solid transparent;
  background: linear-gradient(transparent, transparent) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
  border-radius: 1rem;
}

.container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  text-align: center;
  padding: var(--mb-3);
  width: fit-content;
  /* margin-top: var(--mb-6) !important; */
  font-family: "StylishFont";
}

.time {
  font-size: 2.5rem;
  font-weight: 700;
  margin: 0 var(--mb-2);
}

.textContainer {
  text-align: center;
  margin-bottom: var(--mb-2);
  font-size: 1.2rem;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  /* .wrapper {
    width: 275px;
  } */

  .time {
    font-size: 1.5rem;
    margin: 0 var(--mb-2);
  }
}
