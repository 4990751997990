.description {
  border: solid 5px;
  border-radius: 10px;
  padding: var(--mb-1) var(--mb-6);
  font-size: 1rem;
  margin: var(--mb-4) 0;
  width: 50%;
  line-height: var(--mb-4);
  box-sizing: border-box;
  box-shadow: 0 0 10px black;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  transition: 0.5s all;
  background-color: var(--bg);
  border: 3px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}
.description p {
  padding: var(--mb-3) 0;
  font-weight: bold;
  color: var(--white);
  width: 100%;
}
.description p:not(:last-child) {
  border-bottom: 3px solid var(--orange1);
}
@media only screen and (max-width: 1023px) {
  .description {
    width: 80%;
    padding: var(--mb-1) var(--mb-2);
  }
  /* .description p {
    padding: var(--mb-2) 0;
  } */
}
