.wrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  background: var(--orange2);
  background: -moz-linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  background: linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ); */
}

.componentContainer {
  display: flex;
  justify-content: center;
  width: 95vw;
  height: 90%;
  border-radius: 1rem;
  overflow: hidden;

  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
}

.designContainer {
  flex-basis: 40%;
  background-image: url(../../assets/images/LoginRegistration/login_design_1.jpg);
  background-size: cover;
  background-position: 0%;
}

.formContainer {
  flex-basis: 60%;
  text-align: center;
  padding: var(--mb-4) 0;
  display: flex;
  flex-direction: column;
  z-index: 2;
  overflow-y: auto;
}

.formContent {
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.formContent > h1 {
  margin: var(--mb-3);
  font-family: "StylishFont";
  letter-spacing: 0.1rem;
}

.formContent > p {
  margin: var(--mb-2);
}

.formBox {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.googleIcon {
  border: 0.5px solid transparent;
  border-radius: 50%;
  padding: var(--mb-1);
  width: 50px;
  justify-content: center;
  cursor: pointer;
  transition: 0.2s all;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
}

.divider {
  font-size: 0.9rem;
}

.divider:before,
.divider:after {
  background-color: var(--backgroundText);
  content: "";
  display: inline-block;
  height: 0.01rem;
  position: relative;
  vertical-align: middle;
  width: 10%;
}

.divider:before {
  right: 0.5em;
  margin-left: -50%;
}

.divider:after {
  left: 0.5em;
  margin-right: -50%;
}

.formLink {
  color: var(--orange3);
  cursor: pointer;
  transition: 0.2s all;
}

.formLink:hover {
  opacity: 0.7;
}

.buttonContainer {
  display: flex;
  justify-content: center;
  margin: var(--mb-2) 0;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .googleIcon:hover {
    transform: scale(1.2);
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .designContainer {
    display: none;
  }
  .formContainer {
    flex-basis: 100%;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .formContainer {
    padding: var(--mb-4) var(--mb-2);
  }
}
