.wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  min-height: 100vh;
  justify-content: center;
}

.parallax {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.blurDiv {
  width: 100%;
  height: 100%;
  position: absolute;
  filter: blur(3px);
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  opacity: 0.5;
  background-image: url(../../assets/images/landing_rotated.JPG);
}

.blurDiv > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.landing_container {
  text-align: center;
  color: white;
  position: relative;
  width: 90%;
}

.landing_container > h1 {
  font-size: 5rem;
  font-family: "StylishFont";
  letter-spacing: 0.5rem;
}

.landing_container > p {
  font-size: 1.2rem;
  margin: var(--mb-5) 0;
}

.landing_container > .button_container {
  margin: var(--mb-6) 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.button_1 {
  margin-bottom: var(--mb-2);
}

.wrapper_imported {
  width: 100%;
  /* display: flex;
  justify-content: center;
  align-items: center; */

  padding: var(--mb-3);
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ); */
}

.details_wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: var(--mb-6) 0;

  background-color: var(--bg);
  background-attachment: fixed;
  border-radius: 1rem;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
}

/* Details nav */

.details_nav_container {
  width: 90%;
  display: flex;
  overflow-x: auto;
  justify-content: center;
  white-space: nowrap;
}

.details_nav {
  padding: var(--mb-1) var(--mb-2);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: bold;
  letter-spacing: 0.1rem;
  margin: 0 var(--mb-2);
  font-size: 1.2rem;
}

.details_nav:first-child {
  border-left: none;
}

.details_nav:last-child {
  border-right: none;
}

.details_nav_active {
  border-bottom-width: 4px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
  border-image: linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  border-image-slice: 1;
}
.heading {
  height: 100% !important;
  font-size: larger;
  font-weight: bolder;
  text-align: center;
}
.round_heading {
  width: 20%;
  padding-top: var(--mb-2);
  float: left;

  min-height: max-content;
  align-items: center;
}
.round_content {
  width: 80%;
  height: inherit;
  float: right;
}
.details_nav_icon {
}

.wrapping {
  display: block;

  margin-bottom: 2%;
}

/* Content */
.border_css {
  width: 70%;
  margin: var(--mb-3) 0;
  padding: 1px;
  border-radius: 3em;

  background-image: linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
}
.content_container {
  /* //margin: var(--mb-3) 0; */
  padding: var(--mb-3);
  border-radius: 3em;
  font-size: 1rem;
  letter-spacing: 0.1rem;
  justify-content: center;
  background-color: var(--bg);
  /* background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%); */

  display: flex;
  /* justify-content: safe center; */
  /* align-items: center; */

  /* align-items: flex-start; */
  margin: auto;
}

.content {
  height: 50vh;
  overflow-y: auto;
  /* vertical-align: middle; */

  display: grid;
  align-content: safe center;

  margin: auto;
}

/* Misc Instructions */
.miscInstructionsContainer {
  max-width: 90%;
}

.miscInstructionsContainer > h3 {
  text-transform: uppercase;
  letter-spacing: 0.1rem;
  margin-bottom: var(--mb-1);
  font-weight: 700;
  font-size: 1.4rem;
}

.miscInstructionsContainer p {
  margin-bottom: var(--mb-1);
  font-style: italic;
}

.normalLine {
  margin: var(--mb-2);
  display: flex;
  align-items: center;
}

.faqContainer {
  margin-bottom: var(--mb-2);
  padding: var(--mb-2) var(--mb-1);
}

.faqContainer > p:first-child {
  font-weight: 900;
  margin-bottom: 3px;
}

/* Speakers */
.speakerWrapper {
  display: flex;
  width: 100%;
  justify-content: center;
  flex-wrap: wrap;
}

.speakerCard {
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: 1.5rem;
  margin: var(--mb-3);
  text-align: center;
  width: 250px;
}

.speakerCard > img {
  border-radius: 50%;
  width: 200px;
  margin-bottom: var(--mb-2);
  cursor: pointer;
  border: 3px solid transparent;
  background: linear-gradient(var(--bg), var(--bg)) padding-box,
    linear-gradient(to right, var(--purple1), var(--orange1)) border-box;
  transition: 0.2s all;
}

.speakerCard > img:hover {
  box-shadow: 0 0 5px var(--bgBlue);
  transform: scale(1.1);
}

.speakerName {
  font-size: 1.3rem;
  font-weight: 800;
  margin-bottom: var(--mb-1);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .border_css {
    width: 90%;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .landing_container > h1 {
    font-size: 4rem;
  }
  .details_nav_container {
    width: 90%;
    display: flex;
    overflow-x: auto;
    align-items: flex-start;
    margin: var(--mb-3) 0;
    white-space: nowrap;
    /* justify-content: flex-start; */
  }

  .wrapper_imported {
    height: auto;

    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ); */
  }

  .normalLine {
    margin: var(--mb-2);
    display: flex;
    align-items: center;
    font-size: large;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .landing_container > h1 {
    font-size: 2rem;
  }

  .content_container {
    /* //margin: var(--mb-3) 0; */
    padding: var(--mb-3);
    border-radius: 3em;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    justify-content: center;
    background-color: #000000;
    background-image: linear-gradient(315deg, #000000 0%, #414141 74%);

    display: flex;
    /* justify-content: safe center; */
    /* align-items: center; */

    align-items: flex-start;
    margin: auto;
  }

  .content {
    height: 50vh;
    overflow-y: auto;
    /* vertical-align: middle; */

    display: grid;
    margin: auto;
  }
  .round_heading {
    width: 100%;
    padding-top: 1.9%;
    min-height: max-content;
    align-items: center;
    float: none;
    text-align: center;
  }
  .round_content {
    width: 100%;
    height: inherit;
  }

  .details_nav_container {
    align-items: flex-start;
    justify-content: flex-start;
  }

  .landing_container > .button_container {
    flex-direction: column;
    align-items: safe center;
  }

  .wrapper_imported {
    /* background-color: var(--bg);
    background-image: none; */

    /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 ); */
  }

  .miscInstructionsContainer > h3 {
    text-align: center;
  }

  .speakerCard {
    width: 175px;
  }
}
