.modalOverlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  backdrop-filter: blur(8px);
}

.modalWrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  right: auto;
  bottom: auto;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  max-width: 75vw;
  max-height: 80vh;
  background-color: var(--bg);
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  border-radius: 1rem;
  outline: none;
  padding: 20px 10px;
}

.modalContainer {
  display: flex;
  justify-content: space-evenly;
  padding: var(--mb-1);
  align-items: center;
}

.imageContainer {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentContainer {
  padding: 20px 0;
  flex-basis: 50%;
  width: 50%;
  word-wrap: break-word;
}

.buttonPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.buttonPanel > * {
  margin: var(--mb-2);
}

.Note {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: var(--bg);
  padding: var(--mb-2);
  border-radius: 2rem;
  margin: var(--mb-4) 0;
  flex-direction: column;
  font-size: 17px;
}

.Note > h4 {
  font-size: 1.5rem;
  letter-spacing: 0.1rem;
}

.Note > * {
  margin-bottom: var(--mb-1);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .modalContainer {
    flex-direction: column;
    padding: var(--mb-1);
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .modalWrapper {
    max-width: 80vw;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .modalWrapper {
    max-width: 90vw;
  }
}
