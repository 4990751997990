.slider * {
  margin-left: auto;
  margin-right: auto;
}
.slider {
  padding: 30px 0;
  height: 40vh; /* Can Adjust Accordingly */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.slide {
  opacity: 0;
  transition-duration: 1s ease;
}
.slide.active {
  opacity: 1;
  transition-duration: 1s;
  transform: scale(1.08);
}
.card {
  box-shadow: 0 0 20px black;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.image {
  width: 100%;
  min-height: 30vh;
  max-height: 50vh;
  object-fit: cover;
}
.text {
  padding: 0 var(--mb-1) var(--mb-1);
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: var(--bg);
}
.subtitle {
  font-size: small;
}
.dots {
  margin-top: var(--mb-1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  border: 3px solid var(--text);
  margin: 0 5px;
  cursor: pointer;
  transition: 0.3s;
}
.dot:hover {
  border: 3px solid var(--text);
  width: 12.5px;
  height: 12.5px;
  margin: 0 10px;
}
.dot.active {
  border: 3px solid var(--text);
  background-color: var(--text);
  width: 12.5px;
  height: 12.5px;
}
/* Media Queries */
@media only screen and (max-width: 1023px) {
  .slider {
    height: 60%; /* Can Adjust Accordingly */
    padding: 10px 0px 50px 0px;
  }
  .text {
    width: 100%; /* Can Adjust Accordingly */
    font-size: var(--mb-2);
    box-shadow: 0 0 20px black;
  }
  /* .subtitle{
      font-size: var(--mb-1);
    } */
  .image {
    width: 100%; /* Can Adjust Accordingly */
    height: 35vh;
    box-shadow: 0 0 20px black;
    object-fit: cover;
  }
  .card {
    box-shadow: none;
    display: block;
    max-width: 600px; /* Can Adjust Accordingly */
    width: 80%;
    height: 60%;
  }
  .dot {
    width: 10px;
    height: 10px;
    border: 2px solid var(--text);
  }
  .dot:hover {
    width: 12.5px;
    height: 12.5px;
  }
  .dot.active {
    width: 12.5px;
    height: 12.5px;
  }
}
@media only screen and (max-width: 767px) {
  .slider {
    margin-top: var(--mb-4);
    height: 40%;
  }
}
