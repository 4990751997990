.wrapper {
  min-height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: var(--z-normal) !important;
  background-image: linear-gradient(
    to right top,
    #321635,
    #232647,
    #0c3450,
    #004050,
    #164a4c
  ) !important;
  padding: 2rem 0;
}

.mainPane {
  width: 100%;
}