.answer_popup {
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.popupBox {
 position: absolute;
  display:flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.7);
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
}

.box {
  position: relative;

  width: 70%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: var(--bg);
  padding: var(--mb-2);
  border-radius: 2rem;

  flex-direction: column;

  margin-top: calc(100vh - 75vh - 20px);
  margin-left: calc(100vw - 80vw - 20px);

  overflow: auto;
  z-index: 10;
}

.buttonPanel {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.buttonPanel > * {
  margin: var(--mb-2);
}

.Note {
  width: 90%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  background-color: var(--bg);
  padding: var(--mb-2);
  border-radius: 2rem;
  margin: var(--mb-4) 0;
  flex-direction: column;
  font-size: 17px ;
}
