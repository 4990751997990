.App {
  position: relative;
  max-width: 100vw !important;
  transition: 0.5s all;
}

.kBrandLogo {
  position: absolute;
  width: 200px;
  top: 15px;
  left: 15px;
  z-index: 100;
}

.colorPaletteGradientBackground,
.colorPaletteGradientBackgroundHover:hover {
  background: var(--orange2);
  background: -moz-linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  background: -webkit-linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
  background: linear-gradient(
    45deg,
    var(--orange2) 0%,
    var(--orange1) 25%,
    var(--red1) 50%,
    var(--pink2) 75%,
    var(--pink1) 100%
  );
}

.toastWrapper {
}

.toastBody {
}

.toastProgress {
}

.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(-100px);
  transition: all 200ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateY(100px);
}

.recaptcha_container {
  margin: var(--mb-2);
}

.recaptcha {
  display: inline-block !important;
}

.customScrollbar::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 1rem;
  background-color: var(--backgroundText);
}

.customScrollbar::-webkit-scrollbar {
  width: 10px;
}

.customScrollbar::-webkit-scrollbar-thumb {
  border-radius: 1rem;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: var(--bgBlue);
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
}
