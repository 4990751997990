.smallCard {
  background-color: #211f1f;
  padding: 1rem 1.5rem;
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  width: 40%;
  box-shadow: 0.1rem 0 0.1rem #02e0e4, 0 0.1rem 0.1rem #d500f8;
}

.registrationStat {
  font-size: 1.25rem;
  color: #aaa7a7;
  font-weight: 800;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.registrationCount {
  color: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
}

.currentCount {
  font-size: 3rem;
  font-weight: 900;
}

.totalCount {
  font-size: 1.25rem;
  font-weight: 600;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .smallCard {
    width: 50%;
    margin: 2rem 0;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
  .smallCard {
    width: 60%;
    margin: 2rem 0;
  }
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .smallCard {
    width: 100%;
    margin: 2rem 0;
  }

  .registrationStat {
    font-size: 0.75rem;
  }

  .registrationCount {
    font-size: 0.75rem;
  }

  .currentCount {
    font-size: 1.5rem;
  }

  .totalCount {
    font-size: 0.75rem;
  }
}
