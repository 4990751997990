.wrapper > section {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #000000;
  background-image: linear-gradient(315deg, #000000 0%, #414141 74%);
  padding-top: var(--mb-5);
}

/* .bgDiv {
  position: absolute;
  width: 100%;
  height: 100%;
}

.bgDiv > img {
  height: 100%;
  object-fit: cover;
  width: 100%;
  filter: blur(3px);
  opacity: 0.7;
} */

.headingContainer {
  margin: var(--mb-4) 0;
}

.navContainer {
  width: 90%;
  margin-bottom: var(--mb-2);
  border-radius: 2rem;
}

.teamWrapper {
  width: 90%;
  /* -webkit-column-count: 8 !important;
  -moz-column-count: 8 !important; */
  column-count: 8 !important;
  /* -webkit-column-width: 20% !important;
  -moz-column-width: 20% !important;
  column-width: 20% !important; */
  padding-bottom: var(--mb-4);
}

.imageContainer {
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
  cursor: pointer;
  margin-bottom: var(--mb-2);
}
.image {
  width: 200% !important;
  -webkit-transition: all 350ms ease;
  transition: all 350ms ease;
}

.imageOn {
  opacity: 1;
}

.imageOn:hover {
  transform: scale(1.1);
}

.imageOff {
  opacity: 0.1;
}

/* Media Queries */

/* Above Tablet */
@media only screen and (min-width: 1024px) {
  .navContainer {
    display: flex;
  }
}

/* Both Tablet and Mobile */
@media only screen and (max-width: 1023px) {
  .navContainer {
    display: block;
    overflow-y: hidden;
    overflow-x: auto;
  }
}

/* Tablet only */
@media only screen and (min-width: 768px) and (max-width: 1023px) {
}

/* Mobile only */
@media only screen and (max-width: 767px) {
  .teamWrapper {
    width: 90%;
    column-count: 4 !important;
  }
}
