:root {
  --curve: 35;
}
* {
  box-sizing: border-box;
}

.cardWrapper {
  width: 250px;
  height: 270px;
  margin: 0;
  position: relative;
  /* overflow-y: hidden; */
  /* transition: height 0.2s all !important; */
  cursor: pointer;
}

.card {
  box-shadow: 5px 5px 15px var(--tertiary);
  position: relative;
  display: block;
  height: 100%;
  border-radius: calc(var(--curve) * 1px);
  overflow: hidden;
  text-decoration: none;
}
.card__image {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.img_container {
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}
.card__overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  border-radius: calc(var(--curve) * 1px);
  background-color: var(--bg);
  transform: translateY(100%);
  transition: 0.2s ease-in-out;
  word-wrap: break-word;
}

.card:hover .card__overlay {
  transform: translateY(0);
}

.card__header {
  position: relative;
  display: flex;
  align-items: center;
  padding: 2em;
  border-radius: calc(var(--curve) * 1px) 0 0 0;
  background-color: var(--bg);
  transform: translateY(-100%);
  transition: 0.2s ease-in-out;
}

.card:hover .card__header {
  transform: translateY(0);
}

.card__title {
  font-size: var(--mb-3);
  font-weight: bolder;
  color: var(--text);
  width: 186px;
  text-align: center;
}

.card__description {
  padding: 0 var(--mb-2) var(--mb-3) var(--mb-4);
  margin: 0;
}
