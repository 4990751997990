.wrapper > section {
  width: 100%;
  /* min-height: 100vh; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
}

.bgDiv {
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.7;
}

.slider_wrapper {
  width: 100%;
}
