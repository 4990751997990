.regBtnContainer {
  font-size: 0.9rem;
  display: inline-block;
  background-image: linear-gradient(
    to right,
    #008080,
    #007772,
    #006f63,
    #006656,
    #025d48
  );
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
}

.regBtnContainer:hover {
    cursor: pointer;
}

.title {
    padding: 0 0.5rem 0 0;
}
